<style media="screen">
  p.note {
    font-size: 0.85rem;
    text-transform: none;
    font-style: initial;
  }
</style>

<template>
    <div class="container">
        <div class="" style="display: flex; flex-direction: row;">
            <a href="https://www.brookeeden.com/" class="button button-wide color-primary">Enter Site</a>
            <a href="https://brookeeden.lnk.to/sunroof" target="_blank" class="button button-wide">Listen Now</a>
        </div>
        <div class="card">
            <div class="flex-item">
                <div class="content-container">

                    <div class="album-callout">
                      <div class="album-thumb">
                        <img src="https://artist-assets.s3.amazonaws.com/brooke-eden/BrookeEden_Sunroof_CVR-LYR_3000x3000.jpg" alt="Brooke Eden - Sunroof">
                      </div>
                      <div class="album-detail" >
                        <h1 class="centered logo">Brook Eden</h1>
                        <h1 class="centered">"Sunroof"</h1>
                      </div>
                    </div>

                    <div>
                        <p>Hey guys, it's Brooke! I'm making a new cut of my "Sunroof" video and want to celebrate you and your love in it! Get out your phones, turn "Sunroof" up, and send us:
                        <ul>
                            <li>You singing along to "Sunroof"</li>
                            <li>Enjoying your own "Sunroof" (No filming and driving - be safe!)</li>
                            <li>Showing off your love and loved ones!</li>
                        </ul>
                        Can't wait to see your own versions, and where you take it! <br>
                        Much love,<br>
                        Brooke</p>

                        <p class="strong">*Please film horizontally!</p>
                    </div>

                    <video class="wide" src="https://artist-assets.s3.amazonaws.com/brooke-eden/BrookeEden-FanSourceVideo.mp4" controls></video>

                    <div class="content-footer">

                        <p v-if="hasErrors">
                            We were unable to process this request. Please try again.
                        </p>

                        <p v-if="hasSuccess">
                            Thanks you for your submission!
                        </p>

                        <button class="button button-outline button-wide color-primary" @click="showUpload = true; showUploadButton = false " v-if="showUploadButton">Upload Your Video</button>

                        <vue-dropzone
                          v-if="showUpload"
                          ref="myVueDropzone"
                          id="dropzone"
                          v-on:vdropzone-success="onSuccess"
                          :options="dropzoneOptions"
                        >
                        </vue-dropzone>


                        <div class="show-signup" v-if="showSignup">
                          <p>Please add your email and accept terms and conditions to complete your entry</p>
                          <p>
                              <small>By submitting any videos, photographs, recordings or written materials (such videos, photographs, recordings, and written material are individually and collectively hereinafter referred to as the “Materials”) to This Is Hit, Inc. d/b/a BBR Music Group (“Company”) hereunder, you hereby agree that, after reading this <a href="https://www.brookeeden.com/sunroof-contest-release-form/" target="_blank">Content Submission Release Form</a> completely and carefully, you understand and agree to all of the terms and conditions set forth in this release agreement.</small>
                          </p>

                            <form @submit.prevent="processForm">
                            <div class="form-el-inline">
                              <label for="email">Your Email Address</label>
                              <input type="email" v-model="email">
                            </div>
                            <div class="form-el-inline row">
                                <input id="contest-checkbox" type="checkbox" name="checkbox" value="true" v-model="checkbox" required> <label for="checkbox">I agree to the terms and conditions</label>
                            </div>
                            <button type="submit" name="submit" class="button button-wide">Submit</button>
                          </form>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  </div>
</template>


<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
      components: {
        vueDropzone: vue2Dropzone
      },
      props: ['contest_id', 'contest_entries'],
      data () {
          return {
            email: null,
            checkbox: false,
            publicKey: "18af1f2ab4a6ae25e2e7",
            tabs: "file camera facebook instagram",
            cropDisabled: "disabled",
            hasSuccess: false,
            hasErrors: false,
            error: false,
            showSignup: false,
            showUploadButton: true,
            showUpload: false,
            entryData: {},
            entryMeta: {
              message: "",
            },
            dropzoneOptions: {
                url: this.$hostname + '/api/imageUpload',
                thumbnailWidth: 150,
                acceptedFiles: 'video/quicktime, video/mpeg, video/mp4, video/3gpp',
                maxFilesize: 60,
                maxFiles: 1,
                addRemoveLinks: true,
                timeout: 180000,
                dictDefaultMessage: "<p>Click or Drag Your Video Here to Upload</p><p class=\"note\"><em>Note files are limited to 60 MB maximum size</em></p>"
            }

          }
      },
      methods: {
        onSuccess(file, response) {
          this.showSignup = true;
          this.showUploadcare = false;
          this.showUpload = false;
          this.entryData = response;
        },

        onError(event) {
          this.hasErrors = true;
        },
        verror(file) {
          this.error = true
          //console.log(file.upload.filename, 'Event : vdropzone-error - ' + file.status);
         // this.$refs.myVueDropzone.removeFile(file)
          // window.toastr.error(file.upload.filename, 'Event : vdropzone-error - ' + file.status)
        },
        processForm: function(){

          axios
          .post(this.$hostname + '/api/entry', {
            contest_id: this.contest_id,
            email: this.email,
            entry_data: this.entryData,
            entry_meta: this.entryMeta
          })
          .then((response) => {
            this.showSignup = false;
            this.hasSuccess = true;
            this.contest_entries.push(response.data.data);
          })
          .catch((error) => {
            this.hasErrors = true;
          });


        }
      },
      computed: {
        charactersLeft() {
            var char = this.entryMeta.message.length,
                limit = 1000;

            return (limit - char) + " of " + limit;
          }

      }
    }
</script>
