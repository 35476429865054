<template>

    <div class="container gallery-container">
      <div class="card">

      <h1>Gallery</h1>

      <ul v-if="contest_entries" class="grid overflow unstyled">
        <template v-for="(entry, index) in filteredEntries">
            <li v-if="fileIsVideo(entry.entry_data[0].type)" class="grid-item video">
                <div class="video-wrapper" @click="modalShow(index)">
                    <video class="primary-video" loop="false" muted preload="metadata" poster="">
                        <source
                        v-bind:src="entry.entry_data.cdnUrl"
                        >
                    </video>
                </div>
            </li>
          <li v-if="fileIsImage(entry.entry_data[0].type)" class="grid-item">
            <img class="grid-item-image" :src="thumbnailImage(entry.entry_data.cdnUrlThumbnail)" @click="modalShow(index)">
          </li>

        </template>
      </ul>

      <div v-if="isModal" class="modal">

        <loader v-bind:loading="loading"></loader>

        <div class="modal-content" :class="{show: !loading}">
          <div class="modal-close" @click="modalClose">X</div>
          <div class="modal-image">
            <div class="modal-navigation">
              <div><div v-if="modalCurrent > 0" @click="modalContentChange('prev')"> < </div></div>
              <div><div v-if="(modalCurrent + 1) < filteredEntries.length" @click="modalContentChange('next')"> > </div></div>
            </div>
            <span v-if="fileIsVideo(filteredEntries[modalCurrent].entry_data[0].type)">
                <video class="primary-video" loop="false" controls playsinline  preload="metadata" poster=""  :loaded="loading = false">
                    <source
                    v-bind:src="filteredEntries[modalCurrent].entry_data.cdnUrl+'?t=0.1'"
                    >
                </video>
            </span>


            <span v-if="fileIsImage(filteredEntries[modalCurrent].entry_data[0].type)">
                <img :src="largeImage(filteredEntries[modalCurrent].entry_data.cdnUrl)" @load="loading = false">
            </span>
        </div>
        </div>
      </div>

        </div>
    </div>
</template>

<script>
    export default {
      props: ['contest_entries'],
      data () {
          return {
            loading: true,
            isModal: false,
            modalSrc: null,
            modalCurrent: null,
            thumbnailPath: "/-/scale_crop/200x200/smart/-/preview/200x200",
            largePath: "/-/scale_crop/800x800/smart/-/preview/800x800"
        }
      },

      methods: {
        thumbnailImage: function(fullPath){
          return fullPath;
        },
        largeImage: function(fullPath){
          return fullPath;
        },
        modalShow: function(index){
          this.modalCurrent = index;
          this.isModal = true;
        },
        modalClose: function(src){
          this.modalMessage =  null;
          this.isModal = false;
          this.loading = true;
        },
        modalContentChange: function(dir){
          this.loading = true;
          setTimeout(() => (dir == 'next') ?  this.modalCurrent++ : this.modalCurrent--, 250);
        },
        fileIsAllowed: function(type) {
            var imageTypes = ['gif', 'jpg', 'jpeg', 'png', 'mp4', 'MP4', 'mov', 'MOV'];

            if (imageTypes.indexOf(type) >= 0) {
              return true;
            }
            return false;

        },
        fileIsImage: function(type) {
          var imageTypes = ['gif', 'jpg', 'jpeg', 'png'];

          if (imageTypes.indexOf(type) >= 0) {
            return true;
          }
          return false;
        },
        fileIsVideo: function(type) {
            var videoTypes = ['mov','mp4', 'MOV', 'MP4'];
            if (videoTypes.indexOf(type) >= 0) {
              return true;
            }
            return false;
        },
        filterEntriesByType: function(el, index, arr){
          return this.fileIsAllowed(el.entry_data[0].type);
        }

      },
      computed: {
        filteredEntries: function() {
          return this.contest_entries.filter(this.filterEntriesByType);
        }
      }
    }

</script>
