/**
 * Define per env settings
 *
 */
const config = (function(){

  if( process.env.NODE_ENV === "production" ){
    Vue.prototype.$hostname = 'https://contestapp.bbrmusicgroup.com';
  } else {
    Vue.prototype.$hostname = 'http://localhost:3000';
  }

  return config;

})();
