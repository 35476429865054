/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * Import app specific settings from settings.js
 */
require('./settings.js');



/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('app', require('./components/App.vue').default);
Vue.component('gallery', require('./components/Gallery.vue').default);
Vue.component('entry-form', require('./components/EntryForm.vue').default);
Vue.component('loader', require('./helpers/loader.vue').default);


/**
 * this is where me make the app available to third party hosts
 *
 */
 /**
  * Add stylesheet to remote host header
  */

  if(document.currentScript.getAttribute("data-artist-site-embed") !== null){
      var appStyles = document.createElement('link');
      appStyles.type = "text/css";
      appStyles.rel = "stylesheet";

      appStyles.href = "https://contestapp.bbrmusicgroup.com/css/app.css" ;

      document.getElementsByTagName('head')[0].appendChild(appStyles);
      document.getElementsByTagName('body')[0].classList.add('app')
      document.write('<div id="app"><app></app></div>');
  }



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import App from './components/App.vue'

const app = new Vue({
    el: '#app',
    components: { App }

});
